import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import './index.css';
import { web3 } from '../../store/web3';
import { networkId } from '../../store/config';
import { login } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: localStorage.getItem('publicAddress'),
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', `Please Change Your Network to Rinkeby Test Network`);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.login(address);
        });
    };

    render() {
        let { address } = this.state;
        let { sticky } = this.props;
        return (
            <div className={`kitfitz-nav ${sticky && 'sticky-nav'}`}>
                <nav className='navbar-kitfitz navbar navbar-expand-lg sidenav' id="">
                    <div className='container-fluid'>
                        <div className="row">
                            <button className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i class='fa fa-bars' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                <div className="close-btn-area">
                                    <button className='navbar-toggler close-button' type='button' data-toggle='collapse'
                                        data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                        aria-label='Toggle navigation' aria-haspopup="true">
                                        <i class='' aria-hidden='true'>&times;</i>
                                    </button>
                                </div>
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'><button onClick={this.connectWallet} className="" type="button">
                                        {address == '' || address == null
                                            ? 'Connect'
                                            : address && address.substring(0, 5) + '.....' + address.substring(37, address.length)
                                        }
                                    </button>
                                    </li>
                                    <li className='nav-item'> <HashLink smooth to="/#about" ><img src={require('../../static/images/landing/signs-btn-2.png')} alt='' /><span>Story</span></HashLink></li>
                                    <li className='nav-item'> <HashLink smooth to="/#mint" ><img src={require('../../static/images/landing/signs-btn-3.png')} alt='' /><span>Minting</span></HashLink></li>
                                    <li className='nav-item'> <HashLink smooth to="/#roadmap" ><img src={require('../../static/images/landing/signs-btn-4.png')} alt='' /><span>Roadmap</span></HashLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </div>
        );
    }
}

const mapDispatchToProps = {
    login
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);