import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import KitFitz from './KitFitz';
import Loader from '../components/loader';
import { web3 } from "../store/web3";
import { networkId, message } from "../store/config";
import { logout, login } from '../store/actions/Auth';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    this.checkEthereum();
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };


  checkEthereum = async () => {
    if (typeof window.ethereum !== "undefined") {

      let publicAddress = (await web3.currentProvider.enable())[0];
      this.props.login(publicAddress);

      // check network
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) {
          EventBus.publish('info', message);
        }
      });

      // if (window.ethereum.currentProvider.isMetaMask) {
      window.ethereum.on("accountsChanged", accounts => {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Account has been changed");
      });

      window.ethereum.on("networkChanged", netId => {
        // if (netId != networkId) {
        this.props.logout();
        window.location.reload();
        EventBus.publish("info", "Network has been changed");
        // }
      });
      // }
    }
  };

  render() {

    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <KitFitz {...props} />} />
            <Route exact path='/KitFitz' component={props => <KitFitz {...props} />} />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);