import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import './index.css';
import '../../app/KitFitz/index.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <div className='footer-kitfitz'>
                <div className="auto-container">
                    <div className="row footer-top">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="footer-widget social-widget">
                                <ul>
                                    <li><a href="https://twitter.com/TheKitFitzInn" target="_blank"> <i className="icon"><img src={require('../../static/images/landing/twitter-footer.png')} alt='' /></i> </a></li>
                                    <li><a href="#" target="_blank"> <i className="icon"><img src={require('../../static/images/landing/discord-footer.png')} alt='' /></i> </a></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer-widget copyright-widget">
                                <p>© 2021 kit fitz Powered by <a href="https://softtik.com/" className="" target="_blank">Softtik Technologies</a></p>
                            </div>
                        </div> */}

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="footer-widget copyright-widget text-right">
                                <a href="/#home" className="" target="_blank"></a>
                                <HashLink smooth to="/#home">Go back</HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;