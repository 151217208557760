export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const logout = (data) => ({
  type: 'LOGOUT',
  payload: data,
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const innerForm = (data) => ({
  type: 'INNER_FORM',
  payload: data,
});
